import {Routes, Route} from 'react-router-dom'
import { Dashboard } from './Dashboard'
import { FundingSource } from './FundingSource'
import { AccountActivity } from './AccountActivity'
import ProtectedRoute from 'modules/auth/components/ProtectedRoute'

export const Wallet = (props) => {
  return (
    <Routes>
      <Route 
        path="/" 
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        } 
      />
      <Route path="/funding-sources" element={<FundingSource />} />
      <Route path="/activity" element={<AccountActivity />} />
    </Routes>
  )
}
