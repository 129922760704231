import {feathers} from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import auth from '@feathersjs/authentication-client'
import { AUTHENTICATION_KEY } from './common'
import store from 'store/store'
import { AuthActions } from 'store/modules/authentication/actions'
import { MessagingActions } from 'store/modules/messaging/actions'

const socket = io(process.env.REACT_APP_BASE_URL)
const socketClient = feathers()

const socketInstance = socketio(socket)

socketClient.configure(socketInstance)

socketClient.configure(auth({
  storage: window.localStorage,
  storageKey: AUTHENTICATION_KEY
}))

if (window.localStorage.getItem(AUTHENTICATION_KEY)) {
  const result = await socketClient.reAuthenticate(true)
  store.dispatch(AuthActions.reAuthenticated(result))
}

socketClient.use('pet-finder', socketInstance.service('pet-finder'), {
  methods: ['report', 'revealReunitePoint', 'confirmReunitePoint', 'questionnairesResponse'],
  events: ['reported', 'revealed', 'confirmed'],
});

socketClient.use('messaging', socketInstance.service('messaging'), {
  methods: ['metadata']
});

socketClient.service('messaging').on('message', data => {
  console.log('event message', data)
  if (data?.automated) {
    store.dispatch(MessagingActions.automated(data))  
  } else {
    store.dispatch(MessagingActions.received(data))
  }
})

socketClient.service('messaging').on('metadata', data => {
  console.log('metadata', data)
  store.dispatch(MessagingActions.update(data))
})

socketClient.service('messaging').on('seen', data => {
  store.dispatch(MessagingActions.seen(data))
})

socketClient.hooks({
  error(context) {
    console.error(`Error in '${context.path}' service method '${context.method}'`, context.error.stack)
  }
})

export {socketClient}
