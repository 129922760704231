import { AttachmentPreview } from '../AttachmentPreview';
import '../chat.css'
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { NavigationMap } from './NavigationMap';
import ImageIcon from '@mui/icons-material/Image';
import { socketClient } from 'client/socket.client';

export const SimpleMessage = (props) => {
  const {data, user} = props

  const openImageInNewTab = (media) => {
    if (media) {
      const query = { key: media.path, noRedirect: false}
      socketClient.service('content').find({ query })
        .then(result => {
          if (result?.url) {
            window.open(result?.url, '_blank');
          }
        })
        .catch(console.log)
    }
  }
  return (
    <div className='flex flex-col justify-end '>
      <div key={data._id} className={`flex items-center ${data.from === user?._id ? 'received justify-start ' : 'sent justify-end '} w-4/5`}>
        <div className={`message`}>
          {data.message} 
          {(data?.automated && data?.metadata?.scan?.location?.length == 2) &&
            <NavigationMap 
              lat={data?.metadata?.scan?.location[1]} 
              lng={data?.metadata?.scan?.location[0]}
              id={`navigation-map-${data?._id}`}
            />
          }

          {(data?.automated && !!data?.attachments?.length) && data?.attachments.map((item, index) => (
            <ImageIcon 
              key={index} 
              onClick={() => openImageInNewTab(item)}
              sx={{cursor: 'pointer', mt: 1}}
            />
          ))}
        </div>
        {data.from !== user?._id  && 
          <DoneAllIcon 
            sx={{ fontSize: 14, ml: 1, color: data.seen ? '#0288d1' : '#888' }} 
          />
        }
      </div>
      
    </div>
  )
}